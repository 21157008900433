
import { Component, Vue, Inject, Prop } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { PROVIDER, SERVICES as S } from "@/config/literals";

import CreateUi from "@/components/Core/Create/CreateUI.vue";
import NuclearUnitAutocomplete from "@/components/General/Autocompletes/Localisation/NuclearUnitAutocompleteWrapper.vue";
import GroupAutocomplete from "@/components/General/Autocompletes/GroupAutocomplete.vue";

import { IHydraulicSystemService } from "@/services";
import { HydraulicSystemInfos } from "@/domain";

@Component({
  components: {
    CreateUi,
    NuclearUnitAutocomplete,
    GroupAutocomplete,
  },
})
export default class SystemCreate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  systems!: HydraulicSystemInfos[];

  get hydraulicSystemService() {
    return this.container.resolve<IHydraulicSystemService>(
      S.HYDRAULIC_SYSTEM_SERVICE
    );
  }

  get forbiddenNames(): string[] {
    return this.nuclearUnitId != null
      ? _.chain(this.systems)
          .filter((s) => s.nuclearUnitId == this.nuclearUnitId)
          .map((s) => s.name)
          .value()
      : [];
  }

  get forbiddenTrigrams(): string[] {
    return this.nuclearUnitId != null
      ? _.chain(this.systems)
          .filter((s) => s.nuclearUnitId == this.nuclearUnitId)
          .map((s) => s.trigram)
          .value()
      : [];
  }

  loading: boolean = false;
  name: string | null = null;
  trigram: string | null = null;
  groupId: string | null = null;
  nuclearUnitId: string | null = null;
  nameRule: string | boolean = true;
  trigramRule: string | boolean = true;

  async create() {
    try {
      this.loading = true;
      const systemCreated = await this.hydraulicSystemService.create({
        name: this.name!,
        trigram: this.trigram!,
        groupId: this.groupId!,
        nuclearUnitId: this.nuclearUnitId!,
      });
      this.$emit("create", systemCreated);
    } finally {
      this.loading = false;
    }
  }

  updateName(name: string) {
    this.name = name;
    this.triggerValidation();
  }

  updateTrigram(trigram: string) {
    this.trigram = trigram;
    this.triggerValidation();
  }

  updateUnitId(id: string) {
    this.nuclearUnitId = id;
    this.triggerValidation();
  }

  isNameAllowed() {
    if (this.name == null) {
      this.nameRule = true;
    } else {
      this.nameRule =
        _.findIndex(
          this.forbiddenNames,
          (s) => s.toUpperCase() == this.name!.toUpperCase()
        ) == -1
          ? true
          : "Ce nom est déjà présent dans la tranche";
    }
  }

  isTrigramAllowed() {
    if (this.trigram == null) {
      this.trigramRule = true;
    } else {
      this.trigramRule =
        _.findIndex(
          this.forbiddenTrigrams,
          (s) => s.toUpperCase() == this.trigram!.toUpperCase()
        ) == -1
          ? true
          : "Ce trigrame est déjà présent dans la tranche";
    }
  }

  triggerValidation() {
    this.isNameAllowed();
    this.isTrigramAllowed();
  }
}
